<template>
  <div style="height: 100%;">
    <Header />
    <ion-content fullscreen scroll-y="false">
      <section class="vue-winwheel" style="margin-top: 20px">

        <div class="wheel-wrapper" :style="{ '--marker-background': markerBackground }">

          <div class="canvas-wrapper">

            <canvas id="canvas" width="400" height="400"></canvas>

          </div>

        </div>

      </section>
      <!-- winner content -->
      <ion-list v-if="winner" class="winner-list">

        <ion-item lines="none" class="ion-margin-horizontal ion-margin-vertical prizetag" style="border-radius: 40px">

          <ion-avatar slot="start" style="padding-right: 10px">

            <ion-icon name="trophy" size="large" color="contrast"></ion-icon>

          </ion-avatar>

          <ion-label style="text-align: center; color: var(--ion-color-contrast);" v-show="winner">

            <h4
              style="text-transform: uppercase; font-family: Nunito, sans-serif; font-weight: bolder; font-size: 1rem;">

              WINNAAR:

            </h4>

            <h2 style="font-size: 25px; text-align: center; font-family: Nunito, sans-serif; font-weight: bolder;">

              {{ winneritem.name }}

            </h2>

          </ion-label>

          <ion-avatar slot="end" style="padding-right: 10px">

            <ion-icon name="trophy" size="large" class="end"></ion-icon>

          </ion-avatar>

        </ion-item>

      </ion-list>
    </ion-content>
  </div>
</template>

<script>
// Import necessary modules and components
import * as Winwheel from "vue-winwheel/Winwheel";
import Header from "../components/Header"
import { useSocketStore } from "../stores/Socket.js";
import { eventBus } from "../main";
import listService from "../services/listService.js";

export default {
  components: {
    Header,
  },
  data() {
    // Define data properties
    return {
      selectedTheme: localStorage.getItem('selectedTheme'),
      spinWheel: null,
      listItems: [],
      listid: {},
      list: {},
      segments: [],
      winner: false,
      socketStore: useSocketStore(),
      guestUser: {
        id: "",
        name: "",
        email: null,
      },
      spinned:false,
      winneritem: {},
    };
  },
  computed: {
    // Compute marker background based on selected theme
    markerBackground() {
      // Determine marker background based on selected theme
      if (this.selectedTheme === 'ow') {
        return `url(${require("../components/spinner-marker-wo.svg")})`;
      }
      else if (this.selectedTheme === 'bg') {
        return `url(${require("../components/spinner-marker-bg.svg")})`;
      }
      else if (this.selectedTheme === 'pw') {
        return `url(${require("../components/spinner-marker-wp.svg")})`;
      }
      else if (this.selectedTheme === 'bp') {
        return `url(${require("../components/spinner-marker-bp.svg")})`;
      }
      else {
        // Default theme
        return `url(${require("../components/spinner-marker-wo.svg")})`;
      }
    },
  },
  methods: {
    // Method to create wheel segments
    async createSegments() {
      // Initialize segments array
      this.segments = [];
      // Get computed CSS properties
      const computedStyle = getComputedStyle(document.documentElement);
      const ionColorcontrast = computedStyle.getPropertyValue('--ion-color-secondary');
      const ionColorPrimary = computedStyle.getPropertyValue('--ion-color-primary');
      let previeusColor = ionColorcontrast;

      return new Promise((resolve) => {
        // Iterate through list items to create segments
        this.listItems.forEach((item, index) => {
          // Determine segment properties based on previous color
          if (previeusColor === ionColorcontrast) {
            let segment = {
              strokeStyle: ionColorcontrast,
              lineWidth: 3,
              fillStyle: ionColorPrimary,
              text: item.name,
              textFillStyle: ionColorcontrast,
            };
            this.segments.push(segment);
            previeusColor = ionColorPrimary;
          } else if (previeusColor === ionColorPrimary) {
            let segment = {
              strokeStyle: ionColorcontrast,
              fillStyle: ionColorcontrast,
              lineWidth: 5,
              text: item.name,
              textFillStyle: ionColorPrimary,
            };
            this.segments.push(segment);
            previeusColor = ionColorcontrast;
          }

          // Resolve the promise when all segments are created
          if (index === this.listItems.length - 1) {
            resolve(this.segments);
          }
        });
      });
    },
    // Method to create spinning wheel
    createWheelSpinning() {
      if (!this.spinWheel) {
        this.spinWheel = new Winwheel.Winwheel({
          numSegments: this.segments.length,
          textFontSize: 18,
          lineWidth: 12,
          segments: this.segments,
          animation: {
            type: "spinToStop",
            duration: 5,
            spins: 5,
            callbackFinished: this.onfinish,
          },
        });
        let prizeNumber;
        if (!this.list.winner_id) {
          prizeNumber = Math.floor(Math.random() * this.segments.length) + 1;
          console.log("test winner id = null", this.list)
        }
        else {
          console.log("winner is not null", this.list)
          prizeNumber = this.listItems.findIndex(item => this.checkWinner(item)) + 1;
        }

        var stopAt =
          (360 / this.segments.length) * prizeNumber -
          360 / this.segments.length / 2;
        this.spinWheel.animation.stopAngle = stopAt;
        this.spinWheel.startAnimation();
      }
    },
    // Method to check if item is winner
    checkWinner(item) {
      return item.id == this.list.winner_id;
    },
    // Method to get winner item
    getWinner() {
      this.winneritem = this.listItems.find(item => item.id === this.list.winner_id)
    },
    // Method to create still wheel (no spinning)
    createWheelStill(){
      this.winner = true;
      if (!this.spinWheel) {
        this.spinWheel = new Winwheel.Winwheel({
          numSegments: this.segments.length,
          textFontSize: 18,
          lineWidth: 12,
          segments: this.segments,
          animation: {
            type: "spinToStop",
            duration: 0,
            spins: 0,
          },
        });
        let prizeNumber = this.listItems.findIndex(item => this.checkWinner(item)) + 1;

        var stopAt =
          (360 / this.segments.length) * prizeNumber -
          360 / this.segments.length / 2;
        this.spinWheel.animation.stopAngle = stopAt;
        this.spinWheel.startAnimation();
      }
    },
    // Method called when component is mounted
    async newMounted() {
      // Get list details and create segments
      this.list = await listService.getListById(this.listid);
      await this.createSegments();
      // Decide whether to create spinning or still wheel
      if(this.spinned)
      {
        console.log("result")
        this.createWheelStill();
      }
      else{
        this.createWheelSpinning();
      }
      // Get winner details
      this.getWinner();
    },
    // Callback method when wheel animation finishes
    onfinish() {
      console.log("DONE")
      this.winner = true;
    }
  },
  // Lifecycle hook when component is mounted
  async mounted() {

  },
  // Lifecycle hook when component is created
  async created() {
    // Event listener for when list items are received
    eventBus.$on("items-grouplist", async (items) => {
      this.listItems = [];
      const computedStyle = getComputedStyle(document.documentElement);
      const ionColorcontrast = computedStyle.getPropertyValue('--ion-color-secondary');
      const ionColorPrimary = computedStyle.getPropertyValue('--ion-color-primary');
      let previeusColor = ionColorcontrast;
      items.items.forEach((item) => {
        if (previeusColor === ionColorcontrast) {
          item.color = "primary";
          previeusColor = ionColorPrimary;
          this.listItems.push(item)
        } else if (previeusColor === ionColorPrimary) {
          item.color = "contrast";
          previeusColor = ionColorcontrast;
          this.listItems.push(item)
        }
      })
      this.newMounted();
    });
  },
  // Lifecycle hook before navigating to another route
  async beforeRouteEnter(to, from, next) {
    const { listid, user,spinned } = to.params;
    const decodedListid = JSON.parse(decodeURIComponent(listid));
    const decodeduser = JSON.parse(decodeURIComponent(user));
    const Spinned = spinned;

    next(async vm => {
      vm.socketStore.setupSocketConnection(decodedListid, decodeduser);
      vm.socketStore.getListItemsSocket(decodedListid)
      vm.guestUser = decodeduser;
      vm.listid = decodedListid;
      vm.spinned = Spinned;
      vm.list = await listService.getListById(decodedListid);
    });
  },
  // Lifecycle hook before leaving current route
  beforeRouteLeave(to, from, next) {
    this.socketStore.disconnectSocket()
      .then(() => {
        // Socket disconnected successfully, navigate
        next();
      })
      .catch(error => {
        // An error occurred while disconnecting socket, still navigate
        console.error("Error disconnecting socket:", error);
        next();
      });
    // If it's not the route you're interested in, simply proceed without disconnecting the socket
    next();
  },
}
</script>


<style scoped>
.vue-winwheel {
  text-align: center;
}

.spin-wheel .segment {
  border-width: 20px;
  /* Adjust this value as needed */
}

.vue-winwheel #modalSpinwheel.custom-modal .content-wrapper .content {
  width: calc(100vw - 30px) !important;
  padding-top: 52px;
}

.vue-winwheel #modalSpinwheel.custom-modal.content-wrapper.content.modal-dismiss {
  top: 12px;
  right: 12px;
}

.vue-winwheel canvas#canvas {
  height: calc(70vh - 40px);
  max-height: 90vw;
}

@keyframes winner-transition {
  0% {
    /* opacity: 0; */
    width: 10%;
    /* transform: translateY(100px); */
  }

  100% {
    /* opacity: 1; */
    width: 90%;
    /* transform: translateY(0); */
  }
}


.vue-winwheel .canvas-wrapper {
  position: relative;
}

.vue-winwheel .canvas-wrapper:after {
  content: "";
  display: block;
  background: var(--ion-color-primary);
  width: 60px;
  height: 60px;
  position: absolute;
  left: calc(50% - 30px);
  margin: auto;
  border-radius: 100%;
  top: calc(50% - 32px);
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.vue-winwheel .wheel-wrapper {
  position: relative;
}

.wheel-wrapper {
  padding-top: 15px;
  z-index: 1;
}

.vue-winwheel .wheel-wrapper:before {
  content: "";
  width: 32px;
  height: 47px;
  position: absolute;
  top: -2px;
  left: calc(50% - 16.4px);
  right: 0;
  display: block;
  z-index: 1;
  background-image: var(--marker-background);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.winner-list {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
}

.prizetag {
  --background: var(--ion-color-primary);
  animation: winner-transition 1s ease;
  width: 80%;
  padding: none !important;
  color: var(--ion-color-primary-contrast);
}

.end {
  color: var(--ion-color-primary-contrast);
}

main {
  padding: 0 !important;
}
</style>